import * as React from 'react';

import { appRoutes, AppRouteType } from '../../utils/appRoutes';
import HeaderStrip from './header-strip';

interface IHeaderProps {}

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
  return (
    <div className="bg-white">
      <HeaderStrip />
      <div className="flex flex-col sm:flex-row items-center justify-between p-4 shadow-md">
        <div className="flex flex-row items-center text-center space-x-4">
          {/* <img src="https://dummyimage.com/50" alt="AtoZ Impex Logistics" /> */}
          <h1 className="text-3xl font-bold">A to Z Impex and Logistics</h1>
        </div>
        <div className="hidden sm:flex flex-col sm:flex-row space-x-8">
          {appRoutes.map((route: AppRouteType) => {
            return <Link key={route.label} route={route} />;
          })}
        </div>
      </div>
    </div>
  );
};

const Link = ({ route }: { route: AppRouteType }) => {
  const hash = window.location.hash;
  const isSelected = hash === route.path;
  //   console.log(isSelected, hash, route.path);
  return (
    <div className={`flex flex-col items-center  font-semibold`}>
      {route.icon()}
      <a href={route.path}>{route.label}</a>
    </div>
  );
};

export default Header;
