import * as React from 'react';

import { Constants } from '../utils/constants';

interface IAddressProps {}

const Address: React.FunctionComponent<IAddressProps> = (props) => {
  return (
    <div className="items-center text-center sm:text-left">
      <p className="text-2xl">Address</p>
      <div className="flex flex-row items-center justify-center sm:justify-start">
        <span className="material-icons p-1">location_on</span>
        <p>Andhra pradesh, India.</p>
      </div>
      <div className="pt-2 space-y-1">
        <p className="text-2xl">Contact Us</p>
        <div className="flex flex-row items-center justify-center sm:justify-start">
          <span className="material-icons p-1">phone</span>
          <a href={`tel:${Constants.phoneNumber}`} className="link ">
            {Constants.phoneNumber}
          </a>
        </div>
        <div className="flex flex-row items-center justify-center sm:justify-start">
          <span className="material-icons p-1">email</span>
          <a href={`mailto:${Constants.email}`} className="link ">
            {Constants.email}
          </a>
        </div>
        <div className="flex flex-row items-center justify-center sm:justify-start">
          <img
            src="/images/social/whatsapp.webp"
            alt="whatsapp"
            height={30}
            width={30}
          />
          <a href={`https://wa.me/+919989966338`} className="link ">
            {Constants.phoneNumber}
          </a>
        </div>
        <div className="flex flex-row items-center justify-center sm:justify-start">
          <a
            href="https://www.instagram.com/atozimpexandlogistics/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center"
          >
            <img
              height={30}
              width={30}
              src="/images/social/instagram.webp"
              alt="instagram"
            />
            <p> /atozimpexandlogistics</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Address;
