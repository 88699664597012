import * as React from 'react';

import Address from '../address';
import FooterStrip from './footer-strip';

interface IFooterProps {}

const Footer: React.FunctionComponent<IFooterProps> = (props) => {
  return (
    <div className="bg-slate-900 text-white" id="contact">
      <div className="p-4 sm:p-24 flex flex-col sm:flex-row space-y-10 sm:space-y-0 sm:space-x-8">
        <div className="flex flex-col flex-1">
          <div className="flex flex-row items-center text-center">
            {/* <img
              src="https://dummyimage.com/80"
              alt="A to Z Impex and Logistics"
            /> */}

            <p className="text-3xl">A to Z Impex and Logistics</p>
          </div>
          <p className="text-center sm:text-left">
            Our company specializes in the export and import of premium-quality
            chillies, sourced directly from the fertile lands of India. With a
            rich heritage in agriculture, we bridge the gap between traditional
            Indian spice growers and global markets, ensuring a seamless supply
            chain of the finest chillies.
          </p>
        </div>
        <div className="flex-1 flex flex-col items-center">
          <Address />
        </div>
      </div>
      <FooterStrip />
    </div>
  );
};

export default Footer;
