import 'react-multi-carousel/lib/styles.css';

import * as React from 'react';
import Carousel from 'react-multi-carousel';

type ProductItem = {
  name: string;
  description: string;
  image: string;
};
const ProductItems: ProductItem[] = [
  {
    name: '5531 Chillies',
    description:
      'Measured in ASTA units, the color value of Byadgi Syngenta 5531 Chilli is considered high, infact it gives a distinct rosy bright red color; it varies in the range of 90 to 120 ASTA.',
    image: '/images/chillies/5531-chillies.jpeg',
  },
  {
    name: 'Teja Variety Chillies',
    description:
      'Southern India is the main region of Teja or S17 red chilli production. It has a capsaicin content of 0.50-0.70% making it more pungent and spicy. The huge demand for Oleoresin, a natural chilli extract, is mainly driving the export of Teja variety to various spice processing industries in several Asian countries.',
    image: '/images/chillies/teja-variety-chillies.jpeg',
  },
  {
    name: 'Wonder Hot Chilli',
    description:
      'Wonder Hot Red Chilli mostly used for its color and pungency. Larger in shape, Thick skin and are popular for its exotic flavor. Royal Spices procure the Wonder Hot Red Chillies from Warangal Market of India. We can export Wonder Hot Red Chilli as per your need in whatever quantity you require.',
    image: '/images/chillies/wonder-hot-chilli.jpeg',
  },
  {
    name: '5531 Chillies',
    description:
      'Measured in ASTA units, the color value of Byadgi Syngenta 5531 Chilli is considered high, infact it gives a distinct rosy bright red color; it varies in the range of 90 to 120 ASTA.',
    image: '/images/chillies/5531-chillies.jpeg',
  },
  {
    name: 'Teja Variety Chillies',
    description:
      'Southern India is the main region of Teja or S17 red chilli production. It has a capsaicin content of 0.50-0.70% making it more pungent and spicy. The huge demand for Oleoresin, a natural chilli extract, is mainly driving the export of Teja variety to various spice processing industries in several Asian countries.',
    image: '/images/chillies/teja-variety-chillies.jpeg',
  },
  {
    name: 'Wonder Hot Chilli',
    description:
      'Wonder Hot Red Chilli mostly used for its color and pungency. Larger in shape, Thick skin and are popular for its exotic flavor. Royal Spices procure the Wonder Hot Red Chillies from Warangal Market of India. We can export Wonder Hot Red Chilli as per your need in whatever quantity you require.',
    image: '/images/chillies/wonder-hot-chilli.jpeg',
  },
];

interface IProductsProps {}

const Products: React.FunctionComponent<IProductsProps> = (props) => {
  return (
    <div className="flex flex-col p-4 bg-slate-100 space-y-8" id="products">
      <p className="text-3xl font-semibold flex flex-row self-center">
        Our Products
      </p>

      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        autoPlay
      >
        {ProductItems.map((item: ProductItem) => {
          return (
            <WithStyles
              description={item.description}
              headline={item.name}
              image={item.image}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

const WithStyles = (props: any) => {
  return (
    <div
      className="mx-4 rounded-md shadow-sm bg-white"
      style={{ height: '480px' }}
    >
      <img
        className="w-full h-full object-cover"
        src={props.image}
        alt={props.headline}
        style={{ height: '220px' }}
      />
      <div className="p-4">
        <h1 className="text-xl font-semibold">{props.headline}</h1>
        <p className="text-md ">{props.description}</p>
      </div>
    </div>
  );
};
export default Products;
