import * as React from 'react';

import AboutUs from './components/aboutus';
import Address from './components/address';
import AppCarousal from './components/carousal';
import ContactForm from './components/contact-form';
import Footer from './components/footer';
import Header from './components/header';
import Products from './components/products';
import WhyChooseUs from './components/whyChooseUs';

interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <div>
      <Header />
      <AppCarousal />
      <AboutUs />
      <Products />
      <WhyChooseUs />

      <Footer />
    </div>
  );
};

export default App;
