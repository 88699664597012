import * as React from 'react';

interface IFooterStripProps {}

const FooterStrip: React.FunctionComponent<IFooterStripProps> = (props) => {
  return (
    <div className="bg-slate-700 p-2 flex flex-col items-center">
      <p className="text-sm text-center">
        Copyright © 2024 A to Z Impex and Logistics. All Rights Reserved.
      </p>
    </div>
  );
};

export default FooterStrip;
