import * as React from 'react';

const icons = [
  {
    icon: 'lock_open',
    text: 'We are trusted',
  },
  {
    icon: 'handshake',
    text: 'The best Quality',
  },
  {
    icon: 'thumb_up',
    text: '100 % Guarantee',
  },
  {
    icon: 'pin_drop',
    text: 'Quick Location',
  },
];

interface IWhyChooseUsProps {}

const WhyChooseUs: React.FunctionComponent<IWhyChooseUsProps> = (props) => {
  return (
    <div className="flex flex-col sm:flex-row items-center p-4 sm:p-24 text-center space-y-4">
      <div className="flex-1 space-y-4">
        <p className="text-3xl font-semibold">Why Choose us?</p>
        <p>
          Our company specializes in the export and import of premium-quality
          chillies, sourced directly from the fertile lands of India. With a
          rich heritage in agriculture, we bridge the gap between traditional
          Indian spice growers and global markets, ensuring a seamless supply
          chain of the finest chillies.
        </p>
      </div>
      <div className="flex-1 flex flex-col items-center">
        {icons.map((icon, index) => {
          return (
            <div className="flex flex-row items-center space-x-2">
              <span className="material-icons text-6xl text-red-500">
                {icon.icon}
              </span>
              <p className="text-2xl font-semibold">{icon.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WhyChooseUs;
