import * as React from 'react';

import { Constants } from '../../utils/constants';

interface IHeaderStripProps {}

const HeaderStrip: React.FunctionComponent<IHeaderStripProps> = (props) => {
  return (
    <div className="bg-slate-900">
      <div className="container text-white font-semibold">
        <div className="flex justify-center sm:justify-end items-center py-1">
          <div className="flex flex-row justify-around sm:justify-end items-center w-full">
            <a
              href="mailto:contact@atozimpexlogistics.com"
              className="flex flex-row items-center"
            >
              <span className="material-icons p-1">email</span>
              <p className="hidden sm:block">contact@atozimpexlogistics.com</p>
            </a>
            <Divider />
            <a href="tel:+919989966338" className="flex flex-row items-center">
              <span className="material-icons p-1">phone</span>
              <p className="hidden sm:block">+91-9989966338</p>
            </a>
            <Divider />
            <a
              href="https://wa.me/+919989966338"
              className="flex flex-row items-center"
            >
              <img
                src="/images/social/whatsapp.webp"
                alt="whatsapp"
                height={22}
                width={22}
              />
              <p className="hidden sm:block">+91-9989966338</p>
            </a>
            <Divider />
            <a
              href="https://www.instagram.com/atozimpexandlogistics/"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row items-center"
            >
              <img
                height={22}
                width={22}
                src="/images/social/instagram.webp"
                alt="instagram"
              />
              <p className="hidden sm:block"> /atozimpexandlogistics</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Divider = () => {
  return <div className="mx-2 hidden sm:block">|</div>;
};

export default HeaderStrip;
