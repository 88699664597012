import * as React from 'react';

interface IAboutUsProps {}

const AboutUs: React.FunctionComponent<IAboutUsProps> = (props) => {
  return (
    <div className="flex flex-col p-8 space-y-8" id="about">
      <p className="text-3xl font-semibold flex flex-row self-center">
        About Us
      </p>
      <p className="text-center">
        Our company specializes in the export and import of premium-quality
        chillies, sourced directly from the fertile lands of India. With a rich
        heritage in agriculture, we bridge the gap between traditional Indian
        spice growers and global markets, ensuring a seamless supply chain of
        the finest chillies. Our commitment to quality, sustainability, and
        flavor excellence makes us the preferred partner for businesses seeking
        authentic Indian chillies worldwide.
      </p>
      <p className="text-center">
        Founded with the vision of bringing the vibrant flavors of Indian
        chillies to the global stage, our company has established itself as a
        leader in the export and import of high-quality spices. At the heart of
        our operation is a commitment to sustainability and ethical sourcing
        practices, ensuring that every chilli we supply not only meets but
        exceeds international quality standards. Our team works closely with a
        network of skilled farmers across India, leveraging generations of
        agricultural expertise to cultivate chillies that are renowned for their
        intensity, color, and taste. By integrating traditional farming
        techniques with modern logistics and quality control processes, we
        guarantee a product that is as authentic as it is exceptional. As we
        continue to expand our reach, our dedication to excellence and our
        passion for Indian spices drive us forward, making us the trusted choice
        for customers around the world seeking the true essence of Indian
        chillies.
      </p>
      <p className="text-center">
        At A to Z Impex and Logistics, we take pride in our direct partnership
        with farmers, ensuring the freshest and highest quality chilies for our
        customers. From farm to shipment, we guarantee excellence every step of
        the way.
      </p>
    </div>
  );
};

export default AboutUs;
