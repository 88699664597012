export const routePath = {
  home: '#',
  contact: '#contact',
  about: '#about',
  services: '#services',
  products: '#products',
  gallery: '#gallery',
  blog: '#blog',
};

export type AppRouteType = {
  path: string;
  component: JSX.Element;
  label: string;
  icon: () => JSX.Element;
};
export const appRoutes = [
  {
    path: routePath.home,
    component: <div>home</div>,
    label: 'Home',
    icon: () => <span className="material-icons">home</span>,
  },
  {
    path: routePath.about,
    component: <div>about</div>,
    label: 'About',
    icon: () => <span className="material-icons">info</span>,
  },
  // {
  //   path: routePath.services,
  //   component: <div>services</div>,
  //   label: 'Services',
  //   icon: () => <span className="material-icons">work</span>,
  // },
  {
    path: routePath.products,
    component: <div>products</div>,
    label: 'Products',
    icon: () => <span className="material-icons">shopping_basket</span>,
  },
  // {
  //   path: routePath.gallery,
  //   component: <div>gallery</div>,
  //   label: 'Gallery',
  //   icon: () => <span className="material-icons">photo</span>,
  // },
  // {
  //   path: routePath.blog,
  //   component: <div>blog</div>,
  //   label: 'Blog',
  //   icon: () => <span className="material-icons">article</span>,
  // },
  {
    path: routePath.contact,
    component: <div>contact</div>,
    label: 'Contact',
    icon: () => <span className="material-icons">contact_page</span>,
  },
];
