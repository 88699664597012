import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import { Carousel } from 'react-responsive-carousel';

const ImageBasePath = '/images/carousal/';

const images = [
  'close-up-red-chilli-peppers.jpg',
  'dried-chili-pepper-pouring-out-from-sac-floor.jpg',
];
const AppCarousal = () => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showThumbs={false}
      animationHandler={'fade'}
      dynamicHeight={false}
    >
      {images.map((image, index) => {
        return (
          <img
            style={{ height: '500px' }}
            src={`${ImageBasePath}${image}`}
            alt={image}
          />
        );
      })}
    </Carousel>
  );
};

export default AppCarousal;
